export class Search {
  formElement: HTMLFormElement

  constructor(formElement: HTMLFormElement, formClearElement: HTMLElement) {
    this.formElement = formElement
    formClearElement.addEventListener("click", this.clearQuery.bind(this))
  }

  clearQuery() {
    const inputField =
      this.formElement.querySelector<HTMLInputElement>("input[type='text']")
    if (!inputField || !inputField.value) {
      return false
    }
    inputField.value = ""
    this.formElement.submit()
    return true
  }
}
