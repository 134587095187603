import { datadogRum } from "@datadog/browser-rum"
import Rails from "@rails/ujs"
import "@fontsource/roboto"
import "@fontsource/roboto/latin-700.css"
import "@fontsource/noto-sans-jp"
import "@fontsource/noto-sans-jp/700.css"
import "@fortawesome/fontawesome-free/js/all"
import "bootstrap"

import "@/javascripts/libs/eventLogger" // To import the type of window.datadogRum
import { Search } from "@/javascripts/components/search"
import { getCommonData } from "@/javascripts/libs/getCommonData"

const commonData = getCommonData()

// Datadog RUM initialization
if (["staging", "production"].includes(commonData.environment)) {
  const envMap: Record<string, string> = {
    staging: "stg",
    production: "prd",
  }
  datadogRum.init({
    applicationId: "373c061b-f12b-4735-b78c-96f34c2ffae8",
    clientToken: "pubf7095faeedd8c8571d8289fa51d9a02a",
    site: "datadoghq.com",
    service: "mobile-web",
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    env: envMap[commonData.environment as string],
    version: import.meta.env.VITE_RELEASE_VERSION,
  })

  if (commonData.currentUser) {
    datadogRum.setUser({
      id: commonData.currentUser.id,
      name: commonData.currentUser.name,
      email: commonData.currentUser.email,
      project: commonData.currentProject.name,
      organization: commonData.organizationName,
    })
  }
  window.datadogRum = datadogRum

  datadogRum.startSessionReplayRecording()
}

// Start other things
document.addEventListener("DOMContentLoaded", () => {
  // Setup the Rails utilities
  Rails.start()

  // Search element
  const searchFormElem =
    document.querySelector<HTMLFormElement>(".js-search-form")
  const searchFormClearBtnElem = document.querySelector<HTMLElement>(
    ".js-search-form-clear-btn"
  )
  if (searchFormElem && searchFormClearBtnElem)
    new Search(searchFormElem, searchFormClearBtnElem)
})
